/* 路由配置 */
import Vue from 'vue'
import VueRouter from 'vue-router'

//路由组件懒加载,开始访问页面时不加载,访问每一个组件时现加载
//外卖:


//搜索:
Vue.use(VueRouter);


export default new VueRouter({
  routes: [
    { path: '/', redirect: '/index' },
    { path: '/index', component: () => import('@/views/Index'), meta: { showTop: true,showFotter:true } },  //首页
    { path: '/academicExchange', component: () => import('@/views/academicExchange'), meta: { showTop: true ,showFotter:true} }, //学术交流
    { path: '/zone', component: () => import('@/views/saiqu'), meta: { showTop: true ,showFotter:true} }, //赛区公式
    { path: '/period', component: () => import('@/views/period'), meta: { showTop: true ,showFotter:true} }, //往期回顾
    { path: '/exchangeView', component: () => import('@/views/exchangeView'), meta: { showTop: true ,showFotter:true} },         // 学术交流详情页
    { path: '/advantage', component: () => import('@/views/advantage'), meta: { showTop: true ,showFotter:true} },               //我们的优势
    { path: '/brief', component: () => import('@/views/brief'), meta: { showTop: true ,showFotter:true} },
    { path: '/media', component: () => import('@/views/media'), meta: { showTop: true ,showFotter:true} },
    { path: '/retailStores', component: () => import('@/views/retailStores'), meta: { showTop: true ,showFotter:true} },
    { path: '/eyeList', component: () => import('@/views/eyeList'), meta: { showTop: false ,showFotter:false} },
    { path: '/eyeView', component: () => import('@/views/eyeView'), meta: { showTop: false ,showFotter:false} },
    { path: '/newsList', component: () => import('@/views/newsList'), meta: { showTop: false ,showFotter:false} },
    { path: '/newsView', component: () => import('@/views/newsView'), meta: { showTop: false ,showFotter:false} },
    { path: '/eyeprotection', component: () => import('@/views/eyeprotection'), meta: { showTop: false ,showFotter:false} },
    {path:'/cooperate', component: () => import('@/views/cooperate'), meta: { showTop: true ,showFotter:true} },
    {path:'/contactus', component: () => import('@/views/contactus'), meta: { showTop: true ,showFotter:true} },
    {path:'/periodview', component: () => import('@/views/periodview'), meta: { showTop: true ,showFotter:true} },
    // {
    //   path: '/h5', // 移动端首页
    // }

  ],
  scrollBehavior(to, from,savedPosition) {
    console.log( to, from,savedPosition )
    return {
      x: 0,
      y: 0
    }
  },
  

})

// export default new VueRouter({
//   mode: 'history'  //把Router的mode修改为history模式,VueRouter默认的模式为HASH模式
// })


