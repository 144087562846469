<template>
  <div class="Navigation" v-show="isShow">
    <div class="left">
      <img alt="" src="../assets/logo2.png">
      <div>
        <span>

        </span>
      </div>

    </div>

    <div class="right">
      <div :class="['nav', item.img == nowImg ? 'active' : '']" :key="index" @click="gopage(item.img, item.url)"
        @mouseenter="enter(item.img)" v-for="(item, index) in rlist">
        {{ item.title }}

        <!--        <div class="tan" v-if="item.img == 5 && hidden" @mouseenter="hidden = true" @mouseleave="hidden = false">-->
        <!--          <li v-for="(li,index) in item.children" :key="index" @click="newPage(li)">-->
        <!--            {{ li.name }}-->
        <!--          </li>-->
        <!--        </div>-->

      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data: () => {
    return {
      nowImg: 3,
      hidden: false,
      rlist: [
        { title: '首页', img: 3, url: '/index' },
        { title: '关于我们', img: 5, url: '/brief', },
        { title: '通知公告', img: 6, url: '/media' },
        { title: '获奖公示', img: 9, url: '/academicExchange' },
        { title: '赛区公示', img: 10, url: '/zone' },
        { title: '专家评审', img: 11, url: '/retailStores' },
        { title: '往期回顾', img: 12, url: '/period' },
        { title: '合作咨询', img: 13, url: '/cooperate' },
        { title: '联系我们', img: 14, url: '/contactus' },
      ],
    }
  },
  methods: {
    gopage(index, url) {

      this.nowImg = index
      if (this.$route.path == url) {
        return
      }
      this.$router.push({ path: url })
    },
    enter(key) {
      if (key == 5) {
        this.hidden = true
      } else {
        this.hidden = false
      }
    },



  },
  props: {
    isShow: {
      default: true,
      type: Boolean
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.fullPath.split('?')[0] == '/newView') {
          this.nowImg = 6
          return
        }
        if (val.fullPath != '/') {
          this.rlist.map(c => {
            if (c.url == val.fullPath) {
              this.nowImg = c.img
            }
          })
        }
      },
      immediate: true
    },

  },
  created() {
    document.title = '国际朗诵金梅花奖官网';
    this.$get('index/cate')
      .then(res => {
        this.rlist[1].children = res.data
      })
  }

}
</script>

<style lang="less" scoped>
.nav {
  @media screen and (min-width: 900px) {

    margin-right: 2vw;
    height: 25px;
    position: relative;
  }
}

.nav {
  @media screen and (min-width: 1920px) {
    height: 25px;
    margin-right: 3vw;
    position: relative;
  }
}

.Navigation {
  width: 100%;
  height: 4.1667vw;
  z-index: 99999999;
  font-size: 0.8333vw;
  display: flex;
  justify-content: space-between;
  background: #731418;
  box-shadow: 0.0000vw 0.0000vw 0.7813vw 0.0000vw rgba(0, 0, 0, 0.15);


  .nav {
    cursor: pointer;
    color: #fff;

  }

  .active {
    color: #fff;
    border-bottom: 2px solid #fff;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 17.1146vw;
      height: 1.3479vw;
      margin-left: 14.2396vw;
    }

    div {
      height: 2.4479vw;
      display: flex;
      align-items: flex-end;
    }

    span {
      font-size: 0.8333vw;
      font-weight: 550;
      color: #333333;
      margin-left: 2.2917vw;
      vertical-align: bottom;
    }
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7.2396vw;

    //.nav {
    //  margin-right: 3vw;
    //  position: relative;
    //}

    .tan {
      width: 7.0417vw;
      height: 9.1875vw;
      position: absolute;
      z-index: 9999;
      top: 1.4583vw;
      left: -1.0417vw;
      background: #FFFFFF;
      box-shadow: 0.0000vw 0.1563vw 0.3646vw 0.0521vw rgba(0, 0, 0, 0.18);
      border-radius: 0.2083vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.0938vw;

      li {
        list-style: none;
        font-size: 0.7292vw;
        font-weight: 550;
        color: #666666;
      }

      li:hover {
        color: #38a8b6;
      }
    }

  }
}
</style>
