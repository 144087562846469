<template>
  <div id="app">
    <Navigation @enter="enter" v-if="$route.meta.showTop"/>
    <router-view></router-view>
    <Footer  v-if="$route.meta.showFotter"/>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'

import Footer from './components/Footer'


export default {
  name: 'App',
  data () {
    return {
      show:false,
      modalEnter : false,
      hiddenEye : true
    }
  }, mounted () {
    // 根据不同路由跳转不同页面
    if (this._isMobile()) {
      let url = 'http://jinmeihua.com.cn/h5'
      window.location.href= url
    } else {
      console.log('pc端')
      this.$router.replace('/')
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    enter(val){
      this.modalEnter = val
    },
    leave(){
      this.modalEnter = false
    }
  },
  components: { Navigation, Footer }
}
</script>

<style>

body {
  font-family:微软雅黑 ;
  margin: 0;
  font-weight: 550;
}

.container {
  width: 100vw;
  height: 100vh;
}
.modal{
  position: absolute;
  top: 4.8438vw;
  left: 5.2083vw;
  z-index: 1000000;
}
</style>
