<template>
    <div class="footer">

      <div class="info">
        <!--            <p>联系我们:</p>-->
        <p>
         <span class="title">主办单位</span>
        </p>
        <p>
          <span class="cont">{{info.huodongname}}</span>
        </p>

      </div>

      <div class="info">
        <!--            <p>联系我们:</p>-->
        <p>
          <span class="title">联系我们</span>
        </p>
        <p>
          <span  class="cont">联系人:{{ info.telme }}</span>
        </p>
        <p>
          <span  class="cont">联系电话:{{ info.telephone}}</span>
        </p>
        <p>
          <span  class="cont">地址:{{info.address}}</span>
        </p>

      </div>

      <div class="info">
        <!--            <p>联系我们:</p>-->
        <p>
          <span class="title"> 网站备案</span>
        </p>
        <p>
          <span  class="cont">备案号:<a href="https://beian.miit.gov.cn/" target="_blank"> {{info.beian}}</a></span>
        </p>

      </div>



      <div class="qrcd">
        <img :src="$url+info.qrcode">
<!--        <p><span>关注我们</span> </p>-->
      </div>

      <div class="info1">
        <!--            <p>联系我们:</p>-->
        <span  class="cont">关注国际中文朗诵金梅花奖</span>
        <span  class="cont">官方公众号随时了解活动</span>
        <span  class="cont">最新动态</span>


      </div>

      <!--        <div class="info">-->
<!--&lt;!&ndash;            <p>联系我们:</p>&ndash;&gt;-->
<!--            <p>-->
<!--                <span>活动主办方:</span> {{info.huodongname}}-->
<!--            </p>-->
<!--            <p>-->
<!--                <span>联系人:</span>刘老师-->
<!--            </p>-->
<!--            <p>-->
<!--              <span>-->
<!--                联系电话:-->
<!--              </span>{{info.phone}}-->
<!--            </p>-->
<!--          <p>-->
<!--              <span>-->
<!--                地址:-->
<!--              </span>{{info.address}}-->
<!--          </p>-->
<!--            <p>-->
<!--              <span>-->
<!--                备案号:-->
<!--              </span>-->
<!--                <a href = 'https://beian.miit.gov.cn/'  target="_blank">-->
<!--                  京ICP备2023003402号-2-->
<!--                </a>-->
<!--            </p>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: 'Footer',
        data: () => {
            return {
                info: {
                    huodongname:"庞沛（北京）教育科技有限公司",
                    name:'刘老师',
                    phone: '15701681869',
                    address: '北京市大兴区波普中心1号楼2802'
                },
            }
        },
        created() {
            this.getInfo()
        },
        methods: {
            ...mapActions(['setInfo']),
            //获取企业数据
            getInfo() {
                this.$get('index/getValues')
                    .then((result) => {
                        let info = this.info
                        result.data.map(c => {
                            info[c.name] = c.value
                        })

                        this.setInfo(info)
                        console.log("info",this.info)

                    })
            }
        }
    }
</script>

<style lang="less" scoped>
.title{
  font-size: 18px;
}
.cont{
  color:#999;
}
    .footer {
        width: 100%;
        height: 10.8333vw;
        background: #313131;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
      .qrcd{
        margin-top:12px;
        min-width: 7.8958vw;
          img{
            display: block;
            margin:0 auto;
            width:111px;
            height:111px;
          }
        p {
          display: block;
          margin:0 auto;
          font-size: 0.7292vw;
          margin-bottom: 0.6250vw;

          span {
            display: inline-block;
            //width: 3.9792vw;
            //margin-right: 0.5208vw;
            text-align: center;
            display: block;
            margin-left: 0.5208vw;
            margin-top:10px;
          }

        }
      }
        .info {
            min-width: 14.8958vw;
          height:100px;
            //margin-left: 10.1146vw;
        }
      .info1 {
        min-width: 14.8958vw;
        height:130px;
        display: flex;
        flex-direction: column;
        margin-top:90px;
        font-size:14px;
        line-height: 20px;
        color:#fff;
        //margin-left: 10.1146vw;
      }


        div {
            display: flex;
            flex-direction: column;

            p {
                font-size: 0.7292vw;
                margin-bottom: 0.6250vw;

                span {
                    display: inline-block;
                    //width: 3.9792vw;
                    margin-right: 0.5208vw;
                }
                a{
                    font-size: 0.7292vw;
                    color: #ffffff;
                }
            }

            img {
                width: 5.9063vw;
                height: 5.9063vw;
            }
        }
    }
</style>
