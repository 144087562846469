import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import './assets/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.prototype.$url = 'http://pangpeijiaoyu.com/'

import api from '@/util/request'

Vue.prototype.$get = (url, params) => api.get(url, params)
Vue.prototype.$post = (url, params) => api.post(url, params)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: "AHrACeUXX2lDKj2FHxVqfXPTBqlZahKn"
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

Vue.use(VueAwesomeSwiper)
