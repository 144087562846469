
const user = {
  state: {
    info : {}
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
  },

  actions: {

    // 存储用户已经访问的新闻列表
    setInfo({commit},info){
      commit('SET_INFO',info)
    },

  }
}

export default user
